import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import CountUp from "react-countup"
import { FormStatus, initializeHubspotForm, useFormInput } from "../utils/gatsby-helpers"
import { StaticImage } from "gatsby-plugin-image"
import { Modal } from "antd"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { loginUser, logoutUser, useAuthStatus } from "../services/google-oauth"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import Cookies from 'js-cookie';

const ElectronicSignaturePrice = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page
    const forms = data.forms
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { authed, userAuthed } = useAuthStatus()
    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const locale = useFormInput(lang)
    const password = useFormInput("")
    const repassword = useFormInput("")
    const idioma = useFormInput(lang.toUpperCase())
    const prueba_firma = useFormInput("")
    const plans = ["free", "starter", "business"]

    const [showMoreMobile, setShowMoreMobile] = useState(Array(plans.length).fill(false))
    const [change, setChange] = useState(true)
    const [showAlert, setShowAlert] = useState(true)
    const [formStatus, setFormStatus] = useState(FormStatus.init)
    const [showTrySignatureModal, setShowTrySignatureModal] = useState(false)
    const [trySignatureSelected, setTrySignatureSelected] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState(null)

    const [showEmailForm, setShowEmailForm] = useState(false)
    const [showLoginForm, setShowLoginForm] = useState(false)
    const [isFormAvailable, setIsFormAvailable] = useState(true)
    const [signUpError, setSignUpError] = useState(null)
    const [loginError, setLoginError] = useState(null)

    const [showModalPlanFree, setShowModalPlanFree] = useState(false)
    const [showModalPlanStarter, setShowModalPlanStarter] = useState(false)
    const [showModalPlanBusiness, setShowModalPlanBusiness] = useState(false)
    const [showModalPlanEnterprise, setShowModalPlanEnterprise] = useState(false)

    let counters = []

    const loginElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a href="#" onClick={(e) => {
            e.preventDefault()
            setShowLoginForm(true)
            setShowEmailForm(false)
        }}>{props.children}</a>
    }

    const orLoginWithElements = {
        p: ({ node, ...props }) => <span {...props} />
    }

    const signupElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a href="#" onClick={(e) => {
            e.preventDefault()
            setShowLoginForm(false)
        }}>{props.children}</a>
    }

    const onSwitch = () => {
        setChange(!change)

        if (change) {
            counters.map(e => e.start())
        } else {
            counters.map(e => e.reset())
        }
    }

    const itemValueIsEmpty = (item, plan) => {
        return (item.type === "Text" && !item[`${plan}_text`]) || (item.type === "Check" && !item[`${plan}_check`])
    }

    const getItemValueByPlan = (item, plan) => {
        if (itemValueIsEmpty(item, plan)) {
            return <i className="icon-dash" />
        }

        return item.type === "Check" ? <i className="icon-check" /> : item[`${plan}_text`]
    }

    const getItemValueByPlanForMobile = (item, plan) => {
        if (item.type === "Check") {
            return item.name
        }

        return item.name + ": " + item[`${plan}_text`]
    }

    const groupIsEmpty = (group = [], plan) => {
        const filtered = group.filter((item) => itemValueIsEmpty(item, plan))

        return group.length === filtered.length
    }

    const toggleShowMoreMobile = (index) => {
        setShowMoreMobile(showMoreMobile.map((b, i) => i === index ? !b : b))
    }

    const openModalPlan = (plan) => {
        setSelectedPlan(plan)
        setShowModalPlanFree(plan === "free")
        setShowModalPlanStarter(plan === "starter")
        setShowModalPlanBusiness(plan === "business")
        setShowModalPlanEnterprise(plan === "enterprise")
    }

    const cleanForm = () => {
        name.onChange("")
        surname.onChange("")
        email.onChange("")
        password.onChange("")
        repassword.onChange("")
        setSignUpError(null)
    }

    const closeModalForm = () => {
        if (!isFormAvailable) {
            return true
        }

        setSelectedPlan(null)
        setShowModalPlanFree(false)
        setShowModalPlanStarter(false)
        setShowEmailForm(false)
        setShowLoginForm(false)
        cleanForm()
        logoutUser()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail")
            return
        }

        const token = await executeRecaptcha("homepage")
        const json = {
            "firstname": name.value,
            "lastname": surname.value,
            "email": email.value,
            "locale": lang,
            "signature_type": trySignatureSelected.signature_type,
            "kyc": trySignatureSelected.signature_type === "handwritten",
            "g-recaptcha-response": token,
            "idioma": idioma.value,
            "tipo_prueba_firma": prueba_firma.value,
            "tipo_mql": "Product Users",
            "tipo_product_users": "No Product Sign Up"
        }
        const data = JSON.stringify(json)

        setFormStatus(FormStatus.sending)

        fetch(process.env.SIGNATURE_FORM_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        }).then(response => {
            if (response.ok) {
                return response.json()
            }

            throw new Error("Something went wrong")
        }).then(data => {
            setFormStatus(FormStatus.ok)
            console.log(data)
        }).catch(error => {
            setFormStatus(FormStatus.ko)
            console.error(error)
        })
    }

    const handleAccountGoogleLoginRedirect = (e) => {
        e.preventDefault()
        const cookieHubspotutk = Cookies.get('hubspotutk')
        window.location.href = `${process.env.ACCOUNT_GOOGLE_LOGIN_URL}?pack=${selectedPlan}&hubspotutk=${cookieHubspotutk}&locale=${locale.value}`
    }

    const handleSignUpSubmit = async (e) => {
        e.preventDefault()

        setSignUpError(null)

        if (!executeRecaptcha) {
            setSignUpError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        if (password.value.length < 8) {
            setSignUpError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (password.value !== repassword.value) {
            setSignUpError("Las contraseñas no coinciden.")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "name": `${name.value} ${surname.value}`,
            "email": email.value,
            "p": password.value,
            "locale": locale.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884",
            "pack": selectedPlan,
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_SIGN_UP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setSignUpError(dataResponse.msg)
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault()

        setLoginError(null)

        if (password.value.length < 8) {
            setLoginError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": email.value,
            "p": password.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884"
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        console.log(response, dataResponse)

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    const signUpWithGoogle = async () => {
        setSignUpError(null)

        if (!executeRecaptcha) {
            setSignUpError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "google-id": userAuthed.id,
            "name": userAuthed.name,
            "email": userAuthed.email,
            "locale": locale.value,
            //"g-recaptcha-response": token,
            "g-recaptcha-response": "skipr1884",
            "pack": selectedPlan,
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_SIGN_UP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            logoutUser()
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setSignUpError(dataResponse.msg)
    }

    const loginWihGoogle = async () => {
        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": userAuthed.email,
            "google-id": userAuthed.id,
            //"g-recaptcha-response": token,
            "g-recaptcha-response": "skipr1884",
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            logoutUser()
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    useEffect(() => {
        if (authed && userAuthed) {
            if (showLoginForm) {
                loginWihGoogle()
            }/* else if(selectedPlan) {
                signUpWithGoogle()
            }*/
        }
    }, [authed, userAuthed])

    useEffect(() => {
        if (showModalPlanBusiness) {
            initializeHubspotForm(forms.plan_business_form.portal_id, forms.plan_business_form.form_id, "#form-plan-business", forms.plan_business_form.region)
        }
    }, [showModalPlanBusiness])

    useEffect(() => {
        if (showModalPlanEnterprise) {
            initializeHubspotForm(forms.plan_enterprise_form.portal_id, forms.plan_enterprise_form.form_id, "#form-plan-enterprise", forms.plan_enterprise_form.region)
        }
    }, [showModalPlanEnterprise])

    return <NewLayout pageContext={pageContext}>
        <Seo
            lang={pageContext.langKey}
            title={page.seo.title}
            description={page.seo.meta_description}
            translates={pageContext.translates}
            image={page.seo?.image_2?.localFile?.publicURL}
        />

        <main className="main">
            {showAlert && <div className="banner">
                <div className="banner__body">
                    <i className="icon-star"></i>
                    <span>{page.banner_text}</span>
                    <button className="button button--secondary button--secondary--ghost button--sm"
                            onClick={() => setShowTrySignatureModal(true)}>
                        {page.banner_button} <i className="icon-arrow-right"></i>
                    </button>
                </div>
                <div className="banner__action">
                    <button className="button-icon" onClick={() => setShowAlert(!showAlert)}>
                        <i className="icon-close"></i>
                    </button>
                </div>
            </div>}

            <div className="main__section main__section--100 white-section brown">
                <div className="container" data-aos="fade-up">
                    <div className="tag tag--highlight">{page.tag}</div>
                    <h1 className="merriweather">{page.title}</h1>
                    <div className="container__text">
                        <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                        <div className="switcher-group">
                            <h4>{page.annual_text}</h4>
                            <label className="switcher">
                                <input type="checkbox" />
                                <span className="switcher__control" onClick={onSwitch}></span>
                            </label>
                            <h4>{page.monthly_text}</h4>
                        </div>
                    </div>
                    <div className="table-desktop m-t-40 hidden-md">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    <div className="table__cell">
                                        <h6>
                                            {page.features_text}
                                        </h6>
                                    </div>
                                </th>
                                <th>
                                    <div className="table__cell">
                                        <h3>{page.free_plan.name}</h3>
                                        <div className="table__cell__details">
                                            <CountUp start={page.free_plan.annual_amount} end={page.free_plan.monthly_amount} duration={0.5} suffix="€">
                                                {(props) => {
                                                    counters.push(props)

                                                    return <h4 ref={props.countUpRef} />
                                                }}
                                            </CountUp>

                                            <strong>{page.free_plan.subtext_1}</strong>

                                            <div className="details__description">
                                                {change ? page.free_plan.subtext_2 : page.free_plan.subtext_3}
                                            </div>
                                        </div>
                                        <button className="button button--sm" onClick={() => openModalPlan("free")}>{page.free_plan.button.text}</button>
                                    </div>
                                </th>
                                <th>
                                    <div className="table__cell">
                                        <h3>{page.starter_plan.name}</h3>
                                        <div className="table__cell__details">
                                            <CountUp start={page.starter_plan.annual_amount} end={page.starter_plan.monthly_amount} duration={0.5} suffix="€">
                                                {(props) => {
                                                    counters.push(props)

                                                    return <h4 ref={props.countUpRef} />
                                                }}
                                            </CountUp>

                                            <strong>{page.starter_plan.subtext_1}</strong>

                                            <div className="details__description">
                                                {change ? page.starter_plan.subtext_2 : page.starter_plan.subtext_3}
                                            </div>
                                        </div>
                                        <button className="button button--sm" onClick={() => openModalPlan("starter")}>{page.starter_plan.button.text}</button>
                                    </div>
                                </th>
                                <th>
                                    <div className="table__cell table__cell__highlight--top">
                                        <h3>{page.business_plan.name}</h3>
                                        <div className="table__cell__details">
                                            <CountUp start={page.business_plan.annual_amount} end={page.business_plan.monthly_amount} duration={0.5} suffix="€">
                                                {(props) => {
                                                    counters.push(props)

                                                    return <h4 ref={props.countUpRef} />
                                                }}
                                            </CountUp>

                                            <strong>{page.business_plan.subtext_1}</strong>

                                            <div className="details__description">
                                                {change ? page.business_plan.subtext_2 : page.business_plan.subtext_3}
                                            </div>
                                        </div>
                                        <button className="button button--sm" onClick={() => openModalPlan("business")}>{page.business_plan.button.text}</button>
                                        <a href="#" className="more-info" onClick={(e) => {
                                            e.preventDefault();
                                            openModalPlan("business")
                                        }}>{page.business_plan.subtext_4}</a>
                                    </div>
                                </th>
                                <th>
                                    <div className="table__cell">
                                        <h3>{page.enterprise_plan.name}</h3>
                                        <div className="table__cell__details">
                                            <h5>{page.enterprise_plan.subtext_1}</h5>

                                            <div className="details__description">
                                                {change ? page.enterprise_plan.subtext_2 : page.enterprise_plan.subtext_3}
                                            </div>
                                        </div>
                                        <button className="button button--sm" onClick={() => openModalPlan("enterprise")}>{page.enterprise_plan.button.text}</button>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                        </table>
                        {page.plan_characteristics.map((plan_characteristic, index) => {
                            const isLastGroup = index === page.plan_characteristics.length - 1

                            return <div className="table-group" key={index}>
                                <h6>{plan_characteristic.name}</h6>
                                <table className="table">
                                    <tbody>
                                    {plan_characteristic.items.map((item, i) => {
                                        const isLastRow = i === plan_characteristic.items.length - 1

                                        return <tr key={i}>
                                            <td>
                                                <div className="table__cell">
                                                    {item.name}
                                                    {item.tooltip && <span className="tooltip-icon" data-tooltip={item.tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "free")}
                                                    {item.free_tooltip && <span className="tooltip-icon" data-tooltip={item.free_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "starter")}
                                                    {item.starter_tooltip && <span className="tooltip-icon" data-tooltip={item.starter_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`table__cell ${isLastGroup && isLastRow ? "table__cell__highlight--bottom" : "table__cell__highlight--x"}`}>
                                                    {getItemValueByPlan(item, "business")}
                                                    {item.business_tooltip && <span className="tooltip-icon" data-tooltip={item.business_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "enterprise")}
                                                    {item.enterprise_tooltip && <span className="tooltip-icon" data-tooltip={item.enterprise_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        })}
                    </div>
                    
                    <div className="table-mobile show-md">
                        {plans.map((plan, p) => (
                            <div className="table-mobile-block" key={p}>
                                <div className="tile tile--gray tile--gray-thin">
                                    <div className="tile__body">
                                        <h3>{page[`${plan}_plan`].name}</h3>
                                        <div class="tile__body__titles">
                                            <CountUp start={page[`${plan}_plan`].annual_amount} end={page[`${plan}_plan`].monthly_amount} duration={0.5}
                                                    suffix={("€<br>" + page[`${plan}_plan`].subtext_1)}>
                                                {(props) => {
                                                    counters.push(props)

                                                    return <h4 ref={props.countUpRef} />
                                                }}
                                            </CountUp>
                                        </div>
                                        <div className="text--gray fs-16">{change ? page[`${plan}_plan`].subtext_2 : page[`${plan}_plan`].subtext_3}</div>
                                        <button className="button button--sm" onClick={() => openModalPlan(plan)}>{page[`${plan}_plan`].button.text}</button>
                                        {page[`${plan}_plan`].subtext_4 && <a href="#" className="more-info" onClick={(e) => {
                                            e.preventDefault()
                                            openModalPlan(plan)
                                        }}>{page[`${plan}_plan`].subtext_4}</a>}
                                    </div>
                                </div>

                                {page.plan_characteristics.map((plan_characteristic, index) => {
                                    return !groupIsEmpty(plan_characteristic.items, plan) && (index < 2 || showMoreMobile[p]) && <div className="table-mobile-block__list" key={index}>
                                        <h5>{plan_characteristic.name}</h5>
                                        {plan_characteristic.items.map((item, i) => {
                                            return !itemValueIsEmpty(item, plan) && <div className="table-mobile-block__list__item" key={i}>
                                                <i className="icon-check" />
                                                <span>
                                                    {getItemValueByPlanForMobile(item, plan)}
                                                </span>
                                                {item[`${plan}_tooltip`] && <span className="tooltip-icon" data-tooltip={item[`${plan}_tooltip`]}><i className="icon-info"></i></span>}
                                            </div>
                                        })}
                                    </div>
                                })}

                                <div className="container__see-more">
                                    <button className="button button--xl button--secondary" onClick={() => toggleShowMoreMobile(p)}>
                                        {showMoreMobile[p] ? page.view_less : page.view_more}
                                    </button>
                                </div>
                            </div>
                        ))}

                        <div className="table-mobile-block">
                            <div className="tile tile--gray tile--gray-thin">
                                <h4>{page.enterprise_plan.name}</h4>
                                <div className="tile__body">
                                    <strong>{page.enterprise_plan.subtext_1}</strong>
                                    <div className="text--gray fs-16">{change ? page.enterprise_plan.subtext_2 : page.enterprise_plan.subtext_3}</div>
                                </div>
                                <button className="button" onClick={() => openModalPlan("enterprise")}>
                                    {page.enterprise_plan.button.text} <i className="icon-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main__section main__section--100">
                <div className="container">
                    <div className="tile tile--green">
                        <h3>{page.cta.title}</h3>

                        <div className="tile__body">
                            <ReactMarkdown children={page.cta.description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <LinkButton button={page.cta.button} className="button" extras={<i className="icon-arrow-right"></i>} />
                    </div>
                </div>
            </div>

            <Modal
                centered
                onCancel={() => setShowTrySignatureModal(false)}
                afterClose={() => setTrySignatureSelected(null)}
                visible={showTrySignatureModal}
                width={"100%"}
                footer={null}
            >
                {formStatus !== FormStatus.ok && formStatus !== FormStatus.ko && <div>
                    <h4>{page.try_signature_title}</h4>

                    <ReactMarkdown children={page.try_signature_description} rehypePlugins={[rehypeRaw]} />

                    <div className="card-group">
                        {page.try_signature_items.map((item, index) => (
                            <div className={`button-card ${item.id === trySignatureSelected?.id ? "button-card--active" : ""}`} key={index} onClick={() => {
                                setTrySignatureSelected(item)
                                prueba_firma.onChange(item.prueba_firma)
                            }}>
                                <div className="button-card__icon">
                                    <img src={item.icon.localFile.publicURL} alt={item.icon.alternativeText} />
                                </div>
                                <div className="button-card__info">
                                    <div className="button-card__info__title">{item.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {trySignatureSelected && <form onSubmit={handleSubmit} id="signature-form" className="form">
                        <div className="form__group">
                            <div style={{ position: "absolute", height: 0 }}>
                                <input className="input" type="text" name="idioma" {...idioma} style={{ visibility: "hidden" }} />
                                <input className="input" type="text" name="tipo_prueba_firma" {...prueba_firma} style={{ visibility: "hidden" }} />
                                <input className="input" type="text" name="tipo_mql" value="Product Users" style={{ visibility: "hidden" }} />
                                <input className="input" type="text" name="tipo_product_users" value="No Product Sign Up" style={{ visibility: "hidden" }} />
                            </div>

                            <input className="input" type="text" name="firstname" required placeholder={page.try_signature_form_name} {...name} />
                            <input className="input" type="text" name="lastname" required placeholder={page.try_signature_form_surname} {...surname} />
                            <input className="input" type="email" name="email" required placeholder={page.try_signature_form_email} {...email} />
                        </div>
                        <div className="form__checkbox">
                            <div className="checkbox-group">
                                <input className="checkbox" type="checkbox" id="checkbox_id" required />
                                <label htmlFor="checkbox_id">
                                    <ReactMarkdown children={page.try_signature_form_tc} rehypePlugins={[rehypeRaw]} />
                                </label>
                            </div>
                        </div>
                        <div className="form__text">
                            <ReactMarkdown children={page.try_signature_form_tc_description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <div className="form__button">
                            <button type="submit" className="button" disabled={formStatus === FormStatus.sending}>{page.try_signature_form_button}</button>
                        </div>
                    </form>}
                </div>}

                {/* Mensaje en caso de envío de form exitoso */}
                {formStatus === FormStatus.ok && <div className="ant-modal-body__confirmation">
                    <div className="ant-modal-body__confirmation__icon">
                        <StaticImage
                            src={"../new/resources/check.svg"}
                            alt="ok icon"
                            placeholder="blurred"
                        />
                    </div>
                    <div className="ant-modal-body__confirmation__text">
                        <ReactMarkdown children={page.try_signature_form_ok} rehypePlugins={[rehypeRaw]} />
                    </div>
                </div>}

                {/* Mensaje en caso de envío de form fallido */}
                {formStatus === FormStatus.ko && <div className="ant-modal-body__confirmation">
                    <div className="ant-modal-body__confirmation__icon">
                        <StaticImage
                            src={"../new/resources/ko.svg"}
                            alt="ko icon"
                            placeholder="blurred"
                        />
                    </div>
                    <div className="ant-modal-body__confirmation__text">
                        <ReactMarkdown children={page.try_signature_form_ko} rehypePlugins={[rehypeRaw]} />
                    </div>
                </div>}
            </Modal>

            <Modal
                centered
                footer={null}
                maskClosable={false}
                onCancel={closeModalForm}
                title={<>
                    <h4>{showLoginForm ? forms.plan_free_form.title_login : forms.plan_free_form.title}</h4>
                    <ReactMarkdown children={showLoginForm ? forms.plan_free_form.description_login : forms.plan_free_form.description}
                                   rehypePlugins={[rehypeRaw]} />
                </>}
                visible={showModalPlanFree}
                width={"100%"}
                wrapClassName="main__section"
            >
                <div className="ant-modal-body__thin">
                    <div className="button-collapse-group">
                        <button className="button-collapse" onClick={handleAccountGoogleLoginRedirect} disabled={!isFormAvailable}>
                            <div className="button-collapse__header">
                                <StaticImage src="../images/ic_google.png" alt="Google" width={16} height={16} />
                                {forms.plan_free_form.button_google}
                            </div>
                        </button>

                        {showLoginForm && <div className="divider divider--text"><ReactMarkdown children={forms.plan_free_form.or_sign_up} rehypePlugins={[rehypeRaw]}
                                                                                                components={orLoginWithElements} /></div>}

                        {!showLoginForm && <form className={`button-collapse ${showEmailForm ? "button-collapse--expanded" : ""}`}
                                                 onClick={() => setShowEmailForm(true)}
                                                 onSubmit={handleSignUpSubmit}>
                            <div className="button-collapse__header">
                                <i className="icon-mail"></i>
                                {forms.plan_free_form.button_email}
                            </div>

                            {showEmailForm && <div className="button-collapse__body">
                                <input className="input" type="text" required placeholder={forms.plan_free_form.form_name} disabled={!isFormAvailable} {...name} />
                                <input className="input" type="text" required placeholder={forms.plan_free_form.form_lastname} disabled={!isFormAvailable} {...surname} />
                                <input className="input" type="email" required placeholder={forms.plan_free_form.form_email} disabled={!isFormAvailable} {...email} />
                                <input className="input" type="password" required placeholder={forms.plan_free_form.form_password} disabled={!isFormAvailable} {...password} />
                                <input className="input" type="password" required placeholder={forms.plan_free_form.form_repassword} disabled={!isFormAvailable} {...repassword} />
                                <div className="form__checkbox">
                                    <div className="checkbox-group">
                                        <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                        <label htmlFor="checkbox_plan_free">
                                            <ReactMarkdown children={forms.plan_free_form.form_tc_description} rehypePlugins={[rehypeRaw]} />
                                        </label>
                                    </div>
                                </div>
                                <span className="fs-14 text--gray-dark"><ReactMarkdown children={forms.plan_free_form.form_tc_info} rehypePlugins={[rehypeRaw]} /></span>

                                {signUpError && <div className="form__group form__group--vertical m-b-16">
                                    <p className="text--red">{signUpError}</p>
                                </div>}

                                <div className="form__button">
                                    <button type="submit" className="button button--lg" disabled={!isFormAvailable}>{forms.plan_free_form.button_signup}</button>
                                </div>
                            </div>}
                        </form>}

                        {showLoginForm && <form onSubmit={handleLoginSubmit}>
                            <div className="form__login">
                                <input className="input" type="email" required placeholder={forms.plan_free_form.form_email} disabled={!isFormAvailable} {...email} />
                                <input className="input" type="password" required placeholder={forms.plan_free_form.form_password} disabled={!isFormAvailable} {...password} />
                                <div className="form__login__password">
                                    <div className="checkbox-group">
                                        <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                        <label htmlFor="checkbox_plan_free">
                                            <ReactMarkdown children={forms.plan_free_form.remember_me} rehypePlugins={[rehypeRaw]} />
                                        </label>
                                    </div>
                                    <a href="https://account.tecalis.com/password/reset">{forms.plan_free_form.forgot_password}</a>
                                </div>

                                {loginError && <div className="form__group form__group--vertical m-b-16">
                                    <p className="text--red">{loginError}</p>
                                </div>}

                                <button type="submit" className="button button--lg">{forms.plan_free_form.button_login}</button>
                            </div>
                        </form>}

                        {!showLoginForm && <ReactMarkdown children={forms.plan_free_form.login_text} rehypePlugins={[rehypeRaw]} components={loginElements} />}
                        {showLoginForm && <ReactMarkdown children={forms.plan_free_form.signup_text} rehypePlugins={[rehypeRaw]} components={signupElements} />}
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                footer={null}
                maskClosable={false}
                onCancel={closeModalForm}
                title={<>
                    <h3>{showLoginForm ? forms.plan_premium_form.title_login : forms.plan_premium_form.title}</h3>
                    <ReactMarkdown children={showLoginForm ? forms.plan_premium_form.description_login : forms.plan_premium_form.description}
                                   rehypePlugins={[rehypeRaw]} />
                </>}
                visible={showModalPlanStarter}
                wrapClassName="main__section"
                width={"100%"}
            >
                <div className="ant-modal-body__thin">
                    <div className="button-collapse-group">
                        <button className="button-collapse" onClick={handleAccountGoogleLoginRedirect} disabled={!isFormAvailable}>
                            <div className="button-collapse__header">
                                <StaticImage src="../images/ic_google.png" alt="Google" width={16} height={16} />
                                {forms.plan_premium_form.button_google}
                            </div>
                        </button>

                        {showLoginForm && <div className="divider divider--text"><ReactMarkdown children={forms.plan_premium_form.or_sign_up} rehypePlugins={[rehypeRaw]}
                                                                                                components={orLoginWithElements} /></div>}

                        {!showLoginForm && <form className={`button-collapse ${showEmailForm ? "button-collapse--expanded" : ""}`}
                                                 onClick={() => setShowEmailForm(true)}
                                                 onSubmit={handleSignUpSubmit}>
                            <div className="button-collapse__header">
                                <i className="icon-mail"></i>
                                {forms.plan_premium_form.button_email}
                            </div>

                            {showEmailForm && <div className="button-collapse__body">
                                <input className="input" type="text" required placeholder={forms.plan_premium_form.form_name} disabled={!isFormAvailable} {...name} />
                                <input className="input" type="text" required placeholder={forms.plan_premium_form.form_lastname} disabled={!isFormAvailable} {...surname} />
                                <input className="input" type="email" required placeholder={forms.plan_premium_form.form_email} disabled={!isFormAvailable} {...email} />
                                <input className="input" type="password" required placeholder={forms.plan_premium_form.form_password} disabled={!isFormAvailable} {...password} />
                                <input className="input" type="password" required placeholder={forms.plan_premium_form.form_repassword}
                                       disabled={!isFormAvailable} {...repassword} />
                                <div className="form__checkbox">
                                    <div className="checkbox-group">
                                        <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                        <label htmlFor="checkbox_plan_free">
                                            <ReactMarkdown children={forms.plan_premium_form.form_tc_description} rehypePlugins={[rehypeRaw]} />
                                        </label>
                                    </div>
                                </div>
                                <span className="fs-14 text--gray-dark"><ReactMarkdown children={forms.plan_premium_form.form_tc_info} rehypePlugins={[rehypeRaw]} /></span>

                                {signUpError && <div className="form__group form__group--vertical m-b-16">
                                    <p className="text--red">{signUpError}</p>
                                </div>}

                                <div className="form__button">
                                    <button type="submit" className="button button--lg" disabled={!isFormAvailable}>{forms.plan_premium_form.button_signup}</button>
                                </div>
                            </div>}
                        </form>}

                        {showLoginForm && <form>
                            <div className="form__login">
                                <input className="input" type="text" name="plan_free_email" required placeholder={forms.plan_premium_form.form_email} />
                                <input className="input" type="text" name="plan_free_password" required placeholder={forms.plan_premium_form.form_password} />
                                <div className="form__login__password">
                                    <div className="checkbox-group">
                                        <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                        <label htmlFor="checkbox_plan_free">
                                            <ReactMarkdown children={forms.plan_premium_form.remember_me} rehypePlugins={[rehypeRaw]} />
                                        </label>
                                    </div>
                                    <a href="https://account.tecalis.com/password/reset">{forms.plan_premium_form.forgot_password}</a>
                                </div>
                                <button className="button button--lg">{forms.plan_premium_form.button_login}</button>
                            </div>
                        </form>}

                        {!showLoginForm && <ReactMarkdown children={forms.plan_premium_form.login_text} rehypePlugins={[rehypeRaw]} components={loginElements} />}
                        {showLoginForm && <ReactMarkdown children={forms.plan_premium_form.signup_text} rehypePlugins={[rehypeRaw]} components={signupElements} />}
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowModalPlanBusiness(false)}
                visible={showModalPlanBusiness}
                title={<>
                    <h3>{forms.plan_business_form.title}</h3>
                    <ReactMarkdown children={forms.plan_business_form.description} rehypePlugins={[rehypeRaw]} />
                </>}
                width={"100%"}
                footer={null}
            >
                <div id="form-plan-business" className="form-hb"></div>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowModalPlanEnterprise(false)}
                visible={showModalPlanEnterprise}
                title={<>
                    <h3>{forms.plan_enterprise_form.title}</h3>
                    <ReactMarkdown children={forms.plan_enterprise_form.description} rehypePlugins={[rehypeRaw]} />
                </>}
                width={"100%"}
                footer={null}
            >
                <div id="form-plan-enterprise" className="form-hb"></div>
            </Modal>
        </main>
    </NewLayout>
}

export default ElectronicSignaturePrice

export const electronicSignaturePriceQuery = graphql`
    query ($langKey: String) {
        forms: strapi2023Signature (locale: { eq: $langKey }) {
            plan_free_form {
                title
                description
                button_google
                button_email
                form_name
                form_lastname
                form_email
                form_password
                form_repassword
                form_tc_description
                form_tc_info
                button_signup
                login_text
                title_login
                description_login
                or_sign_up
                remember_me
                forgot_password
                button_login
                signup_text
            }
            plan_premium_form {
                title
                description
                button_google
                button_email
                form_name
                form_lastname
                form_email
                form_password
                form_repassword
                form_tc_description
                form_tc_info
                button_signup
                login_text
                title_login
                description_login
                or_sign_up
                remember_me
                forgot_password
                button_login
                signup_text
            }
            plan_business_form {
                title
                description
                region
                portal_id
                form_id
            }
            plan_enterprise_form {
                title
                description
                region
                portal_id
                form_id
            }
        }
        page: strapi2023SignaturePrices (locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            banner_text
            banner_button
            try_signature_title
            try_signature_description
            try_signature_items {
                id
                title
                icon {
                    name
                    alternativeText
                    localFile {
                        publicURL
                    }
                }
                signature_type
            }
            try_signature_form_name
            try_signature_form_surname
            try_signature_form_email
            try_signature_form_tc
            try_signature_form_tc_description
            try_signature_form_button
            try_signature_form_ok
            try_signature_form_ko
            tag
            title
            description
            annual_text
            monthly_text
            features_text
            free_plan {
                name
                annual_amount
                monthly_amount
                subtext_1
                subtext_2
                subtext_3
                button {
                    text
                    url
                    blank
                }
            }
            starter_plan {
                name
                annual_amount
                monthly_amount
                subtext_1
                subtext_2
                subtext_3
                button {
                    text
                    url
                    blank
                }
            }
            business_plan {
                name
                annual_amount
                monthly_amount
                subtext_1
                subtext_2
                subtext_3
                subtext_4
                button {
                    text
                    url
                    blank
                }
            }
            enterprise_plan {
                name
                subtext_1
                subtext_2
                subtext_3
                button {
                    text
                    url
                    blank
                }
            }
            plan_characteristics {
                name
                items {
                    type
                    name
                    tooltip
                    free_text
                    free_check
                    free_tooltip
                    starter_text
                    starter_check
                    starter_tooltip
                    business_text
                    business_check
                    business_tooltip
                    enterprise_text
                    enterprise_check
                    enterprise_tooltip
                }
            }
            cta {
                title
                description
                button {
                    text
                    url
                    blank
                }
            }
            view_more
            view_less
        }
        
    }
`
